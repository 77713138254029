import { userStore } from "~/store/user";

export default defineNuxtRouteMiddleware(async (to) => {
  const useUserStore = userStore();
  const token = useCookie("acc_token");
  if (!token.value && to?.name?.toString().includes("admin")) {
    abortNavigation();
    return navigateTo("/");
  }
  if (token.value) {
    const res = await useUserStore.checkAccTokenExp(token.value);
    if (!res) {
      abortNavigation();
      return navigateTo(`/`);
    }
  }
  try {
    await useUserStore.fetchUserData();
  } catch (error) {
    abortNavigation();
    return navigateTo("/");
  }
  if (
    !useUserStore.permissions.paths.includes("admin") &&
    to?.name?.toString().includes("admin")
  ) {
    abortNavigation();
    return navigateTo("/");
  }
});
